import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  initiated: false,
  error: null,
  profiles: [],
};

const slice = createSlice({
  name: 'user-license',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET PROFILES
    setProfiles(state, action) {
      state.isLoading = false;
      state.initiated = true;
      state.profiles = action.payload;
    },

    // // ADD NEW CONTACT
    // sendProfiles(state, action) {
    //   state.isLoading = false;
    //   state.profiles = [...state.profiles, action.payload];
    // },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading, hasError, setProfiles } = slice.actions;

// ----------------------------------------------------------------------
// **Async Thunk Function to Fetch Invite Profiles**
export const getProfiles = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get('/user/get-user-profiles');
    console.log(response)
    dispatch(setProfiles(response.data));
  } catch (error) {
    dispatch(hasError(error.message || 'Error fetching get profiles'));
  }
};

// export function sendProfile(data) {
//   return async (dispatch) => {
//     const payload = {...data};
//     dispatch(slice.actions.startLoading());

//     const result = await axios.post('/user/invite-profiles', payload);
//     const response = await axios.get('/user/invite-profiles');
//     const profiles = response.data;
//     dispatch(slice.actions.setProfiles(profiles));

//     return result;
//   };
// };


