import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  initiated: false,
  error: null,
  codes: [],
};

const slice = createSlice({
  name: 'invite-code',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET CODES
    setCodes(state, action) {
      state.isLoading = false;
      state.initiated = true;
      state.codes = action.payload;
    },

    // ADD NEW CONTACT
    sendCodes(state, action) {
      state.isLoading = false;
      state.codes = [...state.codes, action.payload];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading, hasError, setCodes } = slice.actions;

// ----------------------------------------------------------------------
// **Async Thunk Function to Fetch Invite Codes**
export const getCodes = () => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.get('/user/invite-codes');
    dispatch(setCodes(response.data));
  } catch (error) {
    dispatch(hasError(error.message || 'Error fetching invite codes'));
  }
};

export function sendCode(data) {
  return async (dispatch) => {
    const payload = {...data};
    dispatch(slice.actions.startLoading());

    const result = await axios.post('/user/invite-codes', payload);
    const response = await axios.get('/user/invite-codes');
    const codes = response.data;
    dispatch(slice.actions.setCodes(codes));

    return result;
  };
};


