import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import PaymentGuard from '../guards/PaymentGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const profile = JSON.parse(localStorage.getItem('profile'));

  const { members } = useSelector((state) => state.familyGroup);
  const memberSelected = members.filter((m) => m.show);

  const allowAdvisor = !!profile && (profile.role === 'Advisor' || memberSelected.length > 0);
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          {/* <PaymentGuard> */}
          <DashboardLayout />
          {/* </PaymentGuard> */}
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'search', element: <GlobalSearch /> },
        { path: 'proposals', element: <Proposals /> },
        {
          path: 'invitedusers',
          element: (
            <RoleBasedGuard accessibleRoles={['admin']}>
              <InvitedUsers />
            </RoleBasedGuard>
          )
        },
        {
          path: 'userlicense',
          element: (
            <RoleBasedGuard accessibleRoles={['admin']}>
              <UserLicense />
            </RoleBasedGuard>
          )
        },
        { path: 'contacts', element: <Contacts /> },
        { path: 'contact/:id', element: <ContactDetail /> },
        { path: 'contact/:id/edit', element: <ContactEdit /> },
        {
          path: 'contact/:id/:userId',
          element: <ContactDetail />
        },
        {
          path: 'contact/:id/:userId/edit',
          element: <ContactEdit />
        },
        { path: 'asset', element: <Assets /> },
        {
          path: allowAdvisor ? 'asset/:type/:id/:userId' : 'asset/:type/:id',
          element: <AssetDetail />
        },
        {
          path: allowAdvisor ? 'asset/:type/:id/:userId/edit' : 'asset/:type/:id/edit',
          element: <AssetEdit />
        },
        { path: 'insurance', element: <Insurance /> },
        {
          path: allowAdvisor ? 'insurance/:type/:id/:userId' : 'insurance/:type/:id',
          element: <InsuranceDetail />
        },
        {
          path: allowAdvisor ? 'insurance/:type/:id/:userId/edit/:renew' : 'insurance/:type/:id/edit/:renew',
          element: <InsuranceEdit />
        },
        {
          path: allowAdvisor ? 'insurance/:type/:id/:userId/edit' : 'insurance/:type/:id/edit',
          element: <InsuranceEdit />
        },
        { path: 'org', element: <Organization /> },
        {
          path: allowAdvisor ? 'org/:type/:id/:userId' : 'org/:type/:id',
          element: <OrganizationDetail />
        },
        {
          path: allowAdvisor ? 'org/:type/:id/:userId/edit' : 'org/:type/:id/edit',
          element: <OrganizationEdit />
        },
        { path: 'file-vault', element: <FileVault /> },
        { path: 'calendar', element: <Calendar /> },
        { path: 'ledger', element: <Ledger /> },
        { path: 'user', element: <User /> },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:id', element: <BlogPost /> },
            { path: 'post/new-post', element: <BlogNewPost /> },
            { path: 'post/:id/edit', element: <BlogEditPost /> }
          ]
        },
        {
          path: 'invoice/:id',
          element: <InvoiceDetails />
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <LandingPage /> },
        { path: 'about', element: <About /> },
        { path: 'confirm', element: <Confirm /> },
        { path: 'contact', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'privacy-policies', element: <PrivacyPage /> },
        { path: 'terms-conditions', element: <TermsPage /> },
        { path: 'email-verify', element: <VerifyCode /> },
        { path: 'accept-invite', element: <AcceptInvite /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const AcceptInvite = Loadable(lazy(() => import('../pages/authentication/AcceptInvite')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GlobalSearch = Loadable(lazy(() => import('../pages/dashboard/GlobalSearch')));
const Proposals = Loadable(lazy(() => import('../pages/dashboard/Proposals')));
const InvitedUsers = Loadable(lazy(() => import('../pages/dashboard/InvitedUsers')));
const UserLicense = Loadable(lazy(() => import('../pages/dashboard/UserLicense')));
const Contacts = Loadable(lazy(() => import('../pages/dashboard/Contacts')));
const ContactDetail = Loadable(lazy(() => import('../pages/dashboard/Contacts/Detail')));
const ContactEdit = Loadable(lazy(() => import('../pages/dashboard/Contacts/Edit')));
const Assets = Loadable(lazy(() => import('src/pages/dashboard/Assets')));
const AssetDetail = Loadable(lazy(() => import('../pages/dashboard/Assets/Detail')));
const AssetEdit = Loadable(lazy(() => import('../pages/dashboard/Assets/Edit')));
const Insurance = Loadable(lazy(() => import('src/pages/dashboard/Insurance')));
const InsuranceDetail = Loadable(lazy(() => import('../pages/dashboard/Insurance/Detail')));
const InsuranceEdit = Loadable(lazy(() => import('../pages/dashboard/Insurance/Edit')));
const Organization = Loadable(lazy(() => import('src/pages/dashboard/Organization')));
const OrganizationDetail = Loadable(lazy(() => import('../pages/dashboard/Organization/Detail')));
const OrganizationEdit = Loadable(lazy(() => import('../pages/dashboard/Organization/Edit')));
const FileVault = Loadable(lazy(() => import('src/pages/dashboard/FileVault')));
const Ledger = Loadable(lazy(() => import('src/pages/dashboard/Ledger')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogEditPost = Loadable(lazy(() => import('../pages/dashboard/BlogEditPost')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const User = Loadable(lazy(() => import('../pages/dashboard/User')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/Invoice/InvoiceDetails')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const Confirm = Loadable(lazy(() => import('../pages/Confirm')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const PrivacyPage = Loadable(lazy(() => import('../pages/Privacy')));
const TermsPage = Loadable(lazy(() => import('../pages/Terms')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
