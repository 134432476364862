import {Icon} from '@iconify/react';
import twitterFill from '@iconify-icons/ri/twitter-x-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';

export const FAM_ROLES = [
  {value: 'family_chair', label: 'Family Chair'},
  {value: 'family_board_member', label: 'Family Board Member'},
  {value: 'head_of_branch', label: 'Head of Branch'},
  {value: 'family_member', label: 'Family Member'}
];

export const GEN_LIST = ['Generation 1', 'Generation 2', 'Generation 3', 'Generation 4'];

export const getFamilyRole = (value) => {
  const target = FAM_ROLES.find((item) => item.value === value);

  return target ? target.label : '';
};

export const SOCIALS = [
  {
    name: 'Facebook',
    id: 'facebookUrl',
    icon: <Icon icon={facebookFill} width={20} height={20} />
  },
  {
    name: 'Instagram',
    id: 'instagramUrl',
    icon: <Icon icon={instagramFilled} width={20} height={20} />
  },
  {
    name: 'Linkedin',
    id: 'linkedinUrl',
    icon: <Icon icon={linkedinFill} width={20} height={20} />
  },
  {
    name: 'Twitter',
    id: 'twitterUrl',
    icon: <Icon icon={twitterFill} width={20} height={20} />
  }
];

export const USER_TYPE = [
  {value: 'individual', label: 'Individual/Family'},
  {value: 'advisor', label: 'Advisor/Staff'},
  {value: 'institutions', label: 'Institutions/Enterprise'}
];

export const INVITE_USER_TYPE = [
  {value: 'individual', label: 'Individual/Family'},
  {value: 'institutions', label: 'Institutions/Enterprise'},

];

export const INVITE_USER_LICENSE_TYPE = [
  {value: 'Single User', label: 'Single User', type :"individual"},
  {value: 'Private Family', label: 'Private Family', type :"individual"},
  {value: 'Multi-Family Office', label: 'Multi-Family Office', type :"institutions"},
  {value: 'Enterprise', label: 'Enterprise', type :"institutions"},

];


export const USER_ADVISOR_TYPE = [
  {value: 'staff', label: 'Staff'},
  {value: 'realEstate', label: 'Real Estate'},
  {value: 'insurance', label: 'Insurance'},
  {value: 'accounting', label: 'Accounting'},
  {value: 'financial', label: 'Financial'},
  {value: 'legal', label: 'Legal'},
  {value: 'other', label: 'Other'}
];
