/**
 *  id number can be null from canopy data (2023/12/18)
 */
import React, {lazy, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useParams, useNavigate} from 'react-router-dom';
import {Icon} from '@iconify/react';
import {useSnackbar} from 'notistack';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// mui
import {
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// api
import {getAddressCoordinates} from 'src/api';
// hooks
import useAddress from 'src/hooks/useAddress';
// route
import {PATH_DASHBOARD} from 'src/routes/paths';
// utils
import {fDate} from 'src/utils/formatTime';
import {fCurrency} from 'src/utils/formatNumber';
// components
import Label from 'src/components/Label';
import Attachments from 'src/components/Attachments';
import {ContactCard, InsuranceCard, ThemeAccordion} from 'src/components/_dashboard/common';
import {ImageCarousel} from 'src/components/_dashboard/assets/common';
import {THEMES, MapWrapperStyle, baseSettings} from 'src/components/_dashboard/assets/common/MapStyle';
// constants
import {getAssetStatusLabel} from 'src/constants/dropdown';
// ----------------------------------------------------------------------
const MapMarkersPopups = lazy(() => import('src/components/map/MapMarkersPopups'));

DetailPage.propTypes = {
  data: PropTypes.object.isRequired,
  inLoading: PropTypes.bool,
  policies: PropTypes.array
};

function RenderStatus(key) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <Label
      variant={isLight ? 'ghost' : 'filled'}
      color={key === 'active' ? 'primary' : key === 'pending' ? 'warning' : 'error'}
      sx={{textTransform: 'capitalize', mx: 'auto'}}
    >
      {getAssetStatusLabel(key)}
    </Label>
  );
}

export default function DetailPage({data}) {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {userId} = useParams();

  const {
    year,
    make,
    customMake,
    amModel,
    nickName,
    idNumber = '',
    // Garaging Address
    garagingAddressId = '',
    owner = [], // Owner
    addInterest = [], // Additional Interest
    marketValue,
    soldDate = '',
    purDate = '',
    assetStatus = 'active',
    // lease vehicle with dates
    isLeasedVehicle = false,
    leasedVehicleDateExecuted = '',
    leasedVehicleDateExpired = '',
    // Registration
    isRegistered = false,
    regState = '',
    regCountry = '',
    regNumber = '',
    // Insurance
    rPolicies = [], // Related Policies
    insuredValue, // Insurance Value
    lRADate, // Last Replacement Appraisal Date
    lastMADate, // Last Market Appraisal Date
    lastMAValue, // Last Market Appraisal Value
    note,
    rValDocs = [], // Replacement Valuation Documents
    purDocs = [], // Purchase Documents
    valDocs = [], // Valuation Documents
    mRecords = [], // Maintenance Records
    regDocs = [], // Market Value Documents
    imageFiles = []
  } = data;

  const garagingAddress = useAddress(garagingAddressId, userId);
  const [mapMarkers, setMapMarkers] = useState([]);

  useEffect(() => {
    async function fetchAddressCoordinates() {
      try {
        let markers = [];
        if (!!garagingAddress.formattedAddress && garagingAddress.formattedAddress !== 'N/A') {
          const crdResponse = await getAddressCoordinates(encodeURIComponent(garagingAddress.formattedAddress));
          if (crdResponse && crdResponse.results && !!crdResponse.results.length) {
            const locData = crdResponse.results[0];
            const latlng = [locData.geometry.location.lat, locData.geometry.location.lng];
            markers.push({
              name: 'Garaging Address',
              street: garagingAddress.address.street,
              apt: garagingAddress.address.apt,
              zipCode: garagingAddress.address.zipCode,
              city: garagingAddress.address.city,
              state: garagingAddress.address.state,
              country: garagingAddress.address.country,
              country_code: garagingAddress.address.country,
              latlng
            });
          } else {
            enqueueSnackbar(crdResponse.error_message || 'Google map api error', {variant: 'error'});
          }
        }

        setMapMarkers(markers);
      } catch (err) {
        setMapMarkers([]);
        enqueueSnackbar(err, {variant: 'error'});
      }
    }

    if (garagingAddress.formattedAddress) fetchAddressCoordinates();
    // eslint-disable-next-line
  }, [garagingAddress.formattedAddress]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={4} sx={{alignItems: 'center'}}>
        <Tooltip title="Back">
          <IconButton onClick={() => navigate(`${PATH_DASHBOARD.assets.root}/?view=${data.type}`)}>
            <ArrowBackIcon width={24} height={24} />
          </IconButton>
        </Tooltip>
        <Typography variant="h5">{nickName}</Typography>
        {RenderStatus(assetStatus)}
      </Stack>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Stack sx={{p: 1}} spacing={1}>
            <Card>
              <CardContent>
                <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="button">Year:</Typography>
                  {year}
                </Stack>
                <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="button">Make:</Typography>
                  {make === 'custom' ? customMake : make}
                </Stack>
                <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="button">Model:</Typography>
                  {amModel}
                </Stack>
                <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="button">VIN:</Typography>
                  {idNumber?.toUpperCase()}
                </Stack>
                <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="button">Leased Vehicle:</Typography>
                  {isLeasedVehicle ? 'YES' : 'NO'}
                </Stack>
                {isLeasedVehicle && leasedVehicleDateExecuted && (
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Date Excuted:</Typography>
                    {fDate(leasedVehicleDateExecuted)}
                  </Stack>
                )}
                {isLeasedVehicle && leasedVehicleDateExpired && (
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Date Expired:</Typography>
                    {fDate(leasedVehicleDateExpired)}
                  </Stack>
                )}
                <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="button">Titled Owner:</Typography>
                  <Stack spacing={1}>
                    {owner.map((info, ind) => (
                      <ContactCard key={ind} id={info} userId={userId} />
                    ))}
                  </Stack>
                </Stack>
                <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                  <Typography variant="button">Additional Interest:</Typography>
                  <Stack spacing={1}>
                    {addInterest.map((info, ind) => (
                      <ContactCard key={ind} id={info} userId={userId} />
                    ))}
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Note" />
              <CardContent>{note}</CardContent>
            </Card>
            <Card>
              <ThemeAccordion>
                <AccordionSummary
                  sx={{margin: 1.25}}
                  expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}
                >
                  <Typography variant="h6">Market Valuation</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{marginLeft: 1.25}}>
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Purchase Price:</Typography>
                    {fCurrency(marketValue)}
                  </Stack>
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Purchased Date:</Typography>
                    {fDate(purDate)}
                  </Stack>
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Sold Date:</Typography>
                    {fDate(soldDate)}
                  </Stack>
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Purchase Documents</Typography>
                    <Attachments files={purDocs} small />
                  </Stack>
                  <Stack
                    sx={{px: 1, mt: 2}}
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="button">Current Valuation:</Typography>
                    {fCurrency(lastMAValue)}
                  </Stack>
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Date of Last Valuation:</Typography>
                    {fDate(lastMADate)}
                  </Stack>
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Valuation Documents:</Typography>
                    <Attachments files={valDocs} small />
                  </Stack>
                </AccordionDetails>
              </ThemeAccordion>
            </Card>
            <Card>
              <ThemeAccordion>
                <AccordionSummary
                  sx={{margin: 1.25}}
                  expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}
                >
                  <Typography variant="h6">Registration</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{marginLeft: 1.25}}>
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Registered:</Typography>
                    {isRegistered ? 'Yes' : 'No'}
                  </Stack>
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">State Registered: </Typography>
                    {regState}
                  </Stack>
                  {regState === 'non-us' && (
                    <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="button">Country Registered: </Typography>
                      {regCountry}
                    </Stack>
                  )}
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Registration Number:</Typography>
                    {regNumber}
                  </Stack>
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Registration Docs:</Typography>
                    <Attachments files={regDocs} small />
                  </Stack>
                </AccordionDetails>
              </ThemeAccordion>
            </Card>
            <Card>
              <ThemeAccordion>
                <AccordionSummary
                  sx={{margin: 1.25}}
                  expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}
                >
                  <Typography variant="h6">INSURANCE</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{marginLeft: 1.25}}>
                  <Stack sx={{p: 1}} spacing={1}>
                    {!!rPolicies.length && (
                      <Stack
                        sx={{px: 1}}
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="button">Active Insurance policies:</Typography>
                        <Stack spacing={1}>
                          {rPolicies.map((pol, index) => (
                            <InsuranceCard key={index} type="automobile" id={pol?.id || pol} userId={userId} />
                          ))}
                        </Stack>
                      </Stack>
                    )}
                    <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="button">Insurable Value:</Typography>
                      {fCurrency(insuredValue)}
                    </Stack>
                    <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="button">Last Replacement Appraisal Date:</Typography>
                      {fDate(lRADate)}
                    </Stack>
                    <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="button">Replacement Valuation Docs:</Typography>
                      <Attachments files={rValDocs} small />
                    </Stack>
                  </Stack>
                </AccordionDetails>
              </ThemeAccordion>
            </Card>
            <Card>
              <CardHeader title="Maintenance Records" />
              <CardContent>
                <Attachments files={mRecords} />
              </CardContent>
            </Card>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack sx={{p: 1}} spacing={1}>
            <Card>
              <CardContent>
                <ImageCarousel data={imageFiles} />
              </CardContent>
            </Card>
            <Card>
              <ThemeAccordion>
                <AccordionSummary
                  sx={{margin: 1.25}}
                  expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} />}
                >
                  <Typography variant="h6">Address</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{marginLeft: 1.25}}>
                  <Stack sx={{px: 1}} spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="button">Garaging Address</Typography>
                    {garagingAddress.formattedAddress}
                  </Stack>
                </AccordionDetails>
              </ThemeAccordion>
            </Card>
            <Card>
              <CardHeader title="Map Markers & Popups" />
              <CardContent>
                <MapWrapperStyle>
                  <MapMarkersPopups {...baseSettings} data={mapMarkers} mapStyle={THEMES.light} />
                </MapWrapperStyle>
              </CardContent>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
