import PropTypes from 'prop-types';
import {createContext} from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';

// ----------------------------------------------------------------------

const initialState = {
  clientFlowData: {inProgress: false, activeStep: 0, client: null, formData: null},
  themeMode: 'light',
  trendShow: false, // Trend switch on the dashboard chart
  themeStretch: false,
  themeExpand: true,
  themeSidebarExpand: true,
  themeSidebarStretch: false,
  advisorView: true,
  onChangeMode: () => {},
  onToggleStretch: () => {},
  onToggleExpand: () => {},
  onToggleAdvisorView: () => {},
  onToggleSidebarStretch: () => {},
  onToggleSidebarExpand: () => {},
};

const SettingsContext = createContext(initialState);

SettingsProvider.propTypes = {
  children: PropTypes.node
};

function SettingsProvider({children}) {
  const [settings, setSettings] = useLocalStorage('settings', {
    clientFlowData: initialState.clientFlowData,
    themeMode: initialState.themeMode,
    trendShow: initialState.trendShow,
    themeStretch: initialState.themeStretch,
    themeExpand: initialState.themeExpand,
    themeSidebarExpand: initialState.themeSidebarExpand,
    themeSidebarStretch: initialState.themeSidebarStretch,
    advisorView: initialState.advisorView
  });

  const onChangeClientFlowData = ({inProgress = false, activeStep = 0, client = null, formData = null}) => {
    setSettings({
      ...settings,
      clientFlowData: {inProgress, activeStep, client, formData}
    });
  };

  const onChangeMode = (event) => {
    setSettings({
      ...settings,
      themeMode: event.target.value
    });
  };

  const onChangeTrend = (value) => {
    setSettings({
      ...settings,
      trendShow: value
    });
  };

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch
    });
  };

  const onToggleExpand = () => {
    setSettings({
      ...settings,
      themeExpand: !settings.themeExpand
    });
  };

  const onToggleSidebarStretch = () => {
    setSettings({
      ...settings,
      themeSidebarStretch: !settings.themeSidebarStretch
    });
  };

  const onToggleSidebarExpand = () => {
    setSettings({
      ...settings,
      themeSidebarExpand: !settings.themeSidebarExpand
    });
  };

  const onToggleAdvisorView = (value) => {
    setSettings({
      ...settings,
      advisorView: value
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // New client data for advisor
        onChangeClientFlowData,
        // Mode
        onChangeMode,
        // Trend switch
        onChangeTrend,
        // Stretch
        onToggleStretch,
        // Expand cards
        onToggleExpand,
        // Sidebar Stretch
        onToggleSidebarStretch,
        // Sidebar Expand cards
        onToggleSidebarExpand,
        // Advisor view mode
        onToggleAdvisorView
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export {SettingsProvider, SettingsContext};
