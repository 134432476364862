import axios from 'src/utils/axios';
// ----------------------------------------------------------------------

// Dashboard - General APP
export async function getDashboardData() {
  try {
    const response = await axios.get('/dashboard');
    return response;
  } catch (e) {
    return e;
  }
}

// Get Notifications
export async function getNotifications() {
  try {
    const response = await axios.get('/notification');
    return response;
  } catch (e) {
    return e;
  }
}

// Update Notifications
export async function updateNotification() {
  try {
    const response = await axios.put(`notification`);
    return response;
  } catch (err) {
    console.error(err);
  }
}

export async function contactSave(payload) {
  try {
    const response = await axios.post('/contact/save', payload);
    return response;
  } catch (e) {
    return e;
  }
}

export async function getCurrentPlan() {
  try {
    const response = await axios.get('/payment/current-plan');
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function getCurrentLicense() {
  try {
    const response = await axios.get('/user/get-current-license');
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function setCurrentLicense(data) {
  try {
    const response = await axios.post('/user/set-current-license',data);
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function cancelPlan() {
  try {
    const response = await axios.delete('/payment/subscription');
    return response;
  } catch (e) {
    return e;
  }
}

export async function getInvoices() {
  try {
    const response = await axios.get('/payment/invoice');
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function getInviteInfo() {
  try {
    const response = await axios.get('/user/invite');
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function getInvoiceById(id) {
  try {
    const response = await axios.get('/payment/invoice');
    return response.data.find((inv) => inv.number === id);
  } catch (e) {
    return e;
  }
}

// Forgot password feature
export async function sendPasswordResetEmail(payload) {
  try {
    const response = await axios.put('/auth/email-reset-password', payload);
    return response;
  } catch (e) {
    return e;
  }
}

// Reset forgot password feature
export async function resetForgotPassword(payload) {
  try {
    const response = await axios.put('/auth/reset-forget-password', payload);
    return response?.data;
  } catch (e) {
    return e;
  }
}

// Fetch risk score card data
export async function getRiskScoreData() {
  try {
    const response = await axios.get('/risk-score-card');
    return response?.data;
  } catch (e) {
    return e;
  }
}

// Update risk score card data
export async function updateRiskScoreData(payload) {
  try {
    const response = await axios.put('/risk-score-card', payload);
    return response;
  } catch (e) {
    return e;
  }
}

// Update risk score logic
export async function updateRiskScoreLogic(payload) {
  try {
    const response = await axios.put('/risk-score-card/change-logic', payload);
    return response;
  } catch (e) {
    return e;
  }
}

// NOTE: add a new address
export async function addAddress(payload, isRealEstateAddress, isAdvisor, userId, isFamilyGroup = false) {
  try {
    const response = await axios.post(
      isAdvisor || isFamilyGroup ? `advisor/address?userId=${userId}&isFamilyGroup=${isFamilyGroup}` : '/address',
      {
        ...payload,
        isRealEstateAddress
      }
    );
    return response?.data;
  } catch (e) {
    return e;
  }
}

// NOTE: Update existing address
export async function updateAddress(addId, payload, isAdvisor, userId, isFamilyGroup = false) {
  try {
    const response = await axios.put(
      isAdvisor || isFamilyGroup
        ? `advisor/address/${addId}?userId=${userId}&isFamilyGroup=${isFamilyGroup}`
        : `/address/${addId}`,
      {
        ...payload
      }
    );

    return response?.data;
  } catch (e) {
    return e;
  }
}

// NOTE: delete address
export async function deleteAddress(addId, isAdvisor, userId, isFamilyGroup = false) {
  try {
    const response = await axios.delete(
      isAdvisor || isFamilyGroup
        ? `advisor/address/${addId}?userId=${userId}&isFamilyGroup=${isFamilyGroup}`
        : `/address/${addId}`
    );

    return response?.data;
  } catch (e) {
    return e;
  }
}

// searches address given a query
export async function searchAddress(search, isRealEstate, isAdvisor, userId, isFamilyGroup = false) {
  try {
    const response = await axios.get(
      isAdvisor || (isFamilyGroup && userId)
        ? `advisor/address?search=${search}${
            isRealEstate ? '&type=realEstate' : ''
          }&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
        : `/address?search=${search}${isRealEstate ? '&type=realEstate' : ''}`
    );

    return response?.data;
  } catch (err) {
    return err;
  }
}

// gets address by Id
export async function getAddress(addressId, isAdvisor, userId, isFamilyGroup = false) {
  try {
    const response = await axios.get(
      (isAdvisor || isFamilyGroup) && userId
        ? `advisor/address/${addressId}?userId=${userId}&isFamilyGroup=${isFamilyGroup}`
        : `/address/${addressId}`
    );

    return response?.data;
  } catch (e) {
    return e;
  }
}

// gets asset by Id
export async function getAssetDetail(type, id, userId, isFamilyGroup = false) {
  try {
    if (!!userId) {
      const response = await axios.get(
        `/advisor/asset/${
          type === 'realEstate' ? 'real-estate' : type
        }?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
      );
      return response?.data;
    } else {
      const response = await axios.get(`/asset/${type === 'realEstate' ? 'real-estate' : type}/${id}`);
      return response?.data;
    }
  } catch (e) {
    return null;
  }
}

// get Insurance by ID
export async function getInsDetail(type, id, userId, isFamilyGroup = false) {
  try {
    if (!!userId) {
      const response = await axios.get(
        `/advisor/insurance/${
          type === 'realEstate' ? 'real-estate' : type
        }?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`
      );
      return response?.data;
    } else {
      const response = await axios.get(`/insurance/${type === 'realEstate' ? 'real-estate' : type}/${id}`);
      return response?.data;
    }
  } catch (e) {
    return null;
  }
}

// get SharedINS
export async function getSharedIns(type, id) {
  return await axios.get(`shared/insurance/${type === 'realEstate' ? 'real-estate' : type}`);
}

// get Address Coordinates
export async function getAddressCoordinates(address) {
  return await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_MAP_GEOCODING}`
  ).then((response) => response.json());
}

// get organization detail
export async function getOrgDetail(type, id, userId, isFamilyGroup = false) {
  if (!!userId)
    return await axios.get(`advisor/organization/${type}/?id=${id}&userId=${userId}&isFamilyGroup=${isFamilyGroup}`);
  else return await axios.get(`/organization/${type}/${id}`);
}

// get zillow data
export async function getZillowData(address) {
  return await axios.get(`https://zillow56.p.rapidapi.com/search?location=${address}`);
}

// get hazards hub data
export async function getHazardHubData(address) {
  return await axios.get(
    `https://api.hazardhub.com/v1/risks?address=${address.apt.replace(/ /g, '+')}&city=${address.city.replace(
      / /g,
      '+'
    )}&state=${address.state.replace(/ /g, '+')}&zip=${address.zipCode}`
  );
}

// -----------------------------------------------------
// get makes
export async function getMakes(makeName) {
  return await axios.get(`/vehicle-api/makes?makeName=${makeName}`);
}

// get model
export async function getModel(makeId, makeName) {
  return await axios.get(`/vehicle-api/model?makeId=${makeId}&modelName=${makeName}`);
}

// get vin
export async function getDecodedVin(vinId) {
  return await axios.get(`/vehicle-api/decode-VIN?vinId=${vinId}`);
}

// -----------------------------------------------------
// get autocomplete address
export async function getAutocompleteAddress(search) {
  return await axios.get(`/address/auto-complete?search=${search}`);
}
